export const desistementsFilters = [
  {
    text: "Date desistement",
    value: "date_desistement",
    selected: []
  },
  {
    text: "Profil",
    value: "profil",
    selected: []
  },
  {
    text: "Pôle",
    value: "pole",
    selected: []
  },
  {
    text: "Ecole",
    value: "nom_ecole",
    selected: []
  },
  {
    text: "Type d'école",
    value: "type_ecole",
    selected: []
  },
  {
    text: "Desistement",
    value: "desistement",
    selected: []
  },
  {
    text: "Motif Desistement",
    value: "motif_desistement",
    selected: []
  },
  {
    text: "Année de desistement",
    value: "annee_desistement",
    selected: []
  },
];
