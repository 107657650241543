export const desistementHeaders = [
  {
    text: "Date désistement",
    value: "date_desistement",
    sortable: false,
    align: 'center',
  },
  {
    text: "Annee du désistement",
    value: "annee_desistement",
    sortable: false,
    align: 'center',
  },
  {
    text: "Nom",
    value: "nom",
    sortable: false,
    align: 'center',
  },
  {
    text: "Prenom",
    value: "prenom",
    sortable: false,
    align: 'center',
  },
  {
    text: "Emploi",
    value: "profil",
    sortable: false,
    align: 'center',
  },
  {
    text: "Pôle",
    value: "pole",
    sortable: false,
    align: 'center',
  },
  {
    text: "Ecole",
    value: "nom_ecole",
    sortable: false,
    align: 'center',
  },
  {
    text: "Type d'école",
    value: "type_ecole",
    sortable: false,
    align: 'center',
  },
  {
    text: "Désistement",
    value: "desistement",
    sortable: false,
    align: 'center',
  },
  {
    text: "Motif Desistement",
    value: "motif_desistement",
    sortable: false,
    align: 'center',
  },
  {
    text: "Complément d'informations",
    value: "info_desistement",
    sortable: false,
    align: 'center',
  },
  {
    text: "Actions",
    value: "actions",
    sortable: false,
    align: 'center',
  },
];
